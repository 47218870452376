import { configureStore } from '@reduxjs/toolkit';
import transferReducer from './slices/transferSlice';
import massTransferReducer from './slices/massTransferSlice';
import appReducer from './slices/appSlice';
import sakuReducer from './slices/sakuSlice';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const transferPersistConfig = {
  key: 'transfer',
  storage: storageSession,
};

const transferPersistReducer = persistReducer(transferPersistConfig, transferReducer);

const massTransferPersistConfig = {
  key: 'massTransfer',
  storage: storageSession,
};

const massTransferPersistReducer = persistReducer(massTransferPersistConfig, massTransferReducer);

const store = configureStore({
  reducer: {
    transfer: transferPersistReducer,
    massTransfer: massTransferPersistReducer,
    app: appReducer,
    saku: sakuReducer,
  },
  devTools: process.env.APP_CONFIG_ENV === 'development',
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
export default store;
