import 'antd/dist/antd.css';
import '../styles/globals.css';
import '../styles/custom-fonts.css';
import '../styles/custom-animations.css';
import '../styles/skeleton.css';
import '../styles/custom-elevations.css';
import '../styles/picker.css';
import 'intro.js/introjs.css';
import type { AppProps } from 'next/app';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from '../application/helpers/query_client';
import Head from 'next/head';
import { Provider } from 'react-redux';
import store, { persistor } from '../application/redux/store';
import { PersistGate } from 'redux-persist/integration/react';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            <meta name="HandheldFriendly" content="true" />
          </Head>
          <Component {...pageProps} />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default MyApp;
