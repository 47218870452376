import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorProps {
  isError?: boolean;
  description?: string;
  title?: string;
  buttonText?: string;
  image?: string;
  onButtonHandler?: any;
}

interface IGlobalAppState {
  globalMessage: string;
  isGlobalLoading: boolean;
  isPartnerLoading: boolean;
  globalError: ErrorProps;
}

const appSlice = createSlice({
  name: 'app',
  initialState: <IGlobalAppState>{
    globalMessage: '',
    globalError: {},
    isGlobalLoading: false,
    isPartnerLoading: false,
  },
  reducers: {
    setGlobalMessage: (state, action: PayloadAction<string>) => {
      state.globalMessage = action.payload;
    },
    setGlobalLoading: (state, action: PayloadAction<boolean>) => {
      state.isGlobalLoading = action.payload;
    },
    setPartnerLoading: (state, action: PayloadAction<boolean>) => {
      state.isPartnerLoading = action.payload;
    },
    setGlobalError: (state, action: PayloadAction<ErrorProps>) => {
      state.globalError = action.payload;
    },
  },
});

export const { setGlobalMessage, setGlobalError, setGlobalLoading, setPartnerLoading } =
  appSlice.actions;
export default appSlice.reducer;
